<template>
  <div>
    <ProjectList />
  </div>
</template>

<script>
import ProjectList from './ProjectList.vue'

export default {
  name: 'CommunityProjects',
  components: { ProjectList },

  data: () => ({
    showAddProjectDialog: false
  }),

  methods: {
    addProject () {
      console.log('add project')
      this.showAddProjectDialog = true
    }
  }
}
</script>
