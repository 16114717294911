/*
To use this mixin, set the following variables:

collectionUrl: the url for the collection

The following variables are available to use this mnixin:

dataTableItems: the items in the data table
*/

export default {
  data: () => ({
    dataTableItems: [],
    dataTableSelectedItem: {},
    showAddDialog: false,
    showDeleteDialog: false,
    showEditDialog: false
  }),

  beforeMount () {
    this.loadDataTableItems()
  },

  methods: {
    addDataTableItem () {
      this.showAddDialog = true
    },

    editDataTableItem (item) {
      this.dataTableSelectedItem = item
      this.showEditDialog = true
    },

    confirmDeleteDataTableItem (item) {
      this.dataTableSelectedItem = item
      this.showDeleteDialog = true
    },

    deleteDataTableItem (item) {
      console.log('deleteDataTableItem', item)
      fetch(`${this.collectionUrl}/${item.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => {
          if (response.ok) {
            this.showDeleteDialog = false
            this.$emit('reload')
          } else {
            throw new Error('Cannot delete item')
          }
        })
    },

    loadDataTableItems () {
      fetch(this.collectionUrl, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.dataTableItems = data
        })
    }
  }

}
