<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title>{{ $t('generic.deleteConfirmationDialog.title') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            {{ $t('generic.deleteConfirmationDialog.text') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ $t('generic.deleteConfirmationDialog.item') }}: {{ itemDescription }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="close" @click="$emit('cancel')">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="confirm" color="primary" @click="$emit('confirm')">{{ $t('terms.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    itemDescription: String
  },

  data: () => ({
    error: null
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('cancel', value)
      }
    }
  }

}
</script>
