<template>
  <ProjectDetailsDialog :show="showDialog" @cancel="showDialog = false" @save="save" />
</template>

<script>
import ProjectDetailsDialog from './ProjectDetailsDialog.vue'

export default {
  props: {
    show: Boolean
  },

  components: { ProjectDetailsDialog },

  data: () => ({
    error: null
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },
  methods: {
    save (project) {
      fetch('/api/v1/community/projects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(project)
      }).then(response => {
        if (response.ok) {
          this.$emit('reload')
          this.showDialog = false
        } else {
          this.error = response.statusText
        }
      })
    }
  }
}
</script>
