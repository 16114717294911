<template>
  <div>
    <v-data-table :items="dataTableItems" :headers="headers" :items-per-page="-1">
      <template v-slot:top>
        <v-btn data-cy="addProject" color="primary" @click="addDataTableItem">Add</v-btn>
        <AddProjectDialog :show="showAddDialog" @input="showAddDialog = $event" @reload="loadDataTableItems" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="editProject" @click="editDataTableItem(item)">mdi-pencil</v-icon>
        <v-icon data-cy="deleteProject" @click="confirmDeleteDataTableItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.plannedDuration`]="{ item }">
        {{ formatDuration(item.plannedDuration) }}
      </template>
      <template v-slot:[`item.linkToDetails`]="{ item }">
        <a :href="item.linkToDetails" target="_blank">
          <v-icon v-if="item.linkToDetails">mdi-file-document-outline</v-icon>
        </a>
      </template>
      <template v-slot:[`item.participants`]="{ item }">
        {{ item.participationSpotsLeft }} of {{ item.maxNumberOfParticipants }} left
      </template>
    </v-data-table>
    <EditProjectDialog :show="showEditDialog" :project="dataTableSelectedItem" @input="showEditDialog = $event"
      @confirm="updateDataTableItem(dataTableSelectedItem)" @cancel="showEditDialog = $event"
      @reload="loadDataTableItems" />
    <DeleteConfirmationDialog :show="showDeleteDialog" :itemDescription="dataTableSelectedItem.name"
      @input="showDeleteDialog = $event" @confirm="deleteDataTableItem(dataTableSelectedItem)"
      @cancel="showDeleteDialog = $event" @reload="loadDataTableItems" />
  </div>
</template>

<script>
import AddProjectDialog from './AddProjectDialog.vue'
import DeleteConfirmationDialog from '@/views/generic/DeleteConfirmationDialog.vue'
import EditProjectDialog from './EditProjectDialog.vue'
import DataTable from '@/mixins/dataTable'
import formatting from '@/mixins/formatting'

export default {
  name: 'CommunityProjects',
  components: { AddProjectDialog, EditProjectDialog, DeleteConfirmationDialog },
  mixins: [DataTable, formatting],

  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Participants', value: 'participants' },
      { text: 'Description', value: 'description' },
      { text: 'Planned for', value: 'plannedStartDate' },
      { text: 'Duration', value: 'plannedDuration' },
      { text: 'Document', value: 'linkToDetails' },
      { text: 'Tags', value: 'projectTags' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    collectionUrl: '/api/v1/community/projects'
  })

}
</script>
