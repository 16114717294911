<template>
  <v-dialog v-model="showDialog">
    <v-card>
      <v-card-title><span v-if="project">Edit&nbsp;</span> Project </v-card-title>
      <v-card-text>
        <v-form data-cy="projectForm" v-model="validProject" ref="projectForm" @submit.prevent="saveForm()">
          <v-row>
            <v-col>
              <v-text-field data-cy="projectName" v-model="tempProject.name" label="Name" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea data-cy="projectDescription" v-model="tempProject.description" label="Description" required
                rows="3" auto-grow></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="plannedStartDate" v-model="tempProject.plannedStartDate" label="Start date"
                required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field data-cy="plannedDuration" v-model="tempProject.plannedDuration" label="Duration"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="participantTypes" v-model="tempProject.participantTypes" label="Participant types"
                required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field data-cy="maxNumberOfParticipants" v-model="tempProject.maxNumberOfParticipants"
                label="Maximum number of participants" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field data-cy="linkToDetails" v-model="tempProject.linkToDetails" label="Link to details"
                required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field data-cy="projectTags" v-model="tempProject.projectTags" label="Tags"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-cy="closeDialog" @click="$emit('cancel')">{{ $t('terms.cancel') }}</v-btn>
        <v-btn data-cy="saveDialog" color="primary" @click="saveForm">{{ $t('terms.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    project: Object
  },

  data: () => ({
    tempProject: {},
    validProject: false
  }),

  watch: {
    project () {
      this.tempProject = { ...this.project }
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.error = null
        this.$emit('input', value)
      }
    }
  },

  beforeMount () {
    if (this.project) { this.tempProject = { ...this.project } }
  },

  methods: {
    forceArray (value) {
      if (!value) return []

      if (Array.isArray(value)) {
        return value
      }

      const values = value.split(',')
      values.forEach(value => value.trim())
      return values
    },

    saveForm () {
      if (this.validProject) {
        this.tempProject.participantTypes = this.forceArray(this.tempProject.participantTypes)
        this.tempProject.projectTags = this.forceArray(this.tempProject.projectTags)
        this.$emit('save', this.tempProject)
      }
    }
  }

}
</script>
